import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { endpoint } from '#/store/services/endpoints'
import { fetcher } from '#/store/hooks/request'

export const LOCALE = {
  PORTUGUESE: 'pt',
  ENGLISH: 'en',
  SPANISH: 'es',
}

const splitLanguage = lang => lang.split(/[-_]/)[0]

const defaultLanguage =
  navigator.language || navigator.userLanguage || LOCALE.ENGLISH

const initialState = {
  language: defaultLanguage,
  theme: 'light',
  shortLanguage: splitLanguage(defaultLanguage),
  newVersionAvailable: false,
  version: {
    api: null,
    error: '',
  },
}

if (localStorage.getItem('language') === null) {
  localStorage.setItem('language', initialState.shortLanguage)
  document.documentElement.lang = initialState.shortLanguage
} else {
  document.documentElement.lang = localStorage.getItem('language')
}

export const loadApiVersion = createAsyncThunk(
  'app/loadApiVersion',
  async (_, thunkAPI) => {
    try {
      const { data } = await fetcher({
        controller: endpoint.auth.deploy_version(),
      })

      return { ...data }
    } catch (err) {
      return thunkAPI.rejectWithValue({ message: err.message })
    }
  },
)

// app reducer
const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setCurrentLanguage(state, action) {
      const shortLanguage = splitLanguage(action.payload?.language)

      state.language = action.payload.language
      state.shortLanguage = shortLanguage

      localStorage.setItem('language', shortLanguage)
      document.documentElement.lang = shortLanguage
    },
    setCurrentTheme(state, action) {
      state.theme = action.payload.theme
      window.dispatchEvent(new Event('theme'))
    },
    resetCurrentLanguage(state) {
      state.language = initialState.language
      state.shortLanguage = initialState.shortLanguage

      localStorage.setItem('language', initialState.shortLanguage)
      document.documentElement.lang = initialState.shortLanguage
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadApiVersion.fulfilled, (state, action) => {
        const { major, minor, patch, prerelease } = action.payload
        state.version.api = `${major}.${minor}.${patch}${
          prerelease && `-${prerelease}`
        }`
      })
      .addCase(loadApiVersion.rejected, (state, action) => {
        state.version.error = action.payload.message
      })
  },
})

// selectors
export const getTheme = state => state?.app?.theme
export const getLanguage = state => state?.app?.language
export const getShortLanguage = state => state?.app?.shortLanguage
export const getCurrentAppVersion = state => state?.app?.version?.app
export const getCurrentApiVersion = state =>
  state?.app?.version?.api ?? state?.app?.version?.error

export const { setCurrentTheme, setCurrentLanguage, resetCurrentLanguage } =
  appSlice.actions

export default appSlice.reducer
