import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'
// import omit from 'lodash/omit'
import { fetcher } from '#/store/hooks/request'

export const loadProtocol = createAsyncThunk(
  'form/loadProtocol',
  async (payload, thunkAPI) => {
    const { controller, name, params } = payload

    if (!isEmpty(controller)) {
      try {
        const { data } = await fetcher({ controller, params })

        return {
          name,
          controller,
          data,
          params,
        }
      } catch (e) {
        return thunkAPI.rejectWithValue({
          name,
          controller,
          message: e.message,
          code: e.code,
        })
      }
    }

    return thunkAPI.rejectWithValue({
      name,
      controller,
      message: 'Protocol not found',
    })
  },
)

export const loadProtocolIndex = createAsyncThunk(
  'form/loadProtocolIndex',
  async (payload, thunkAPI) => {
    const { controller, name, params } = payload

    if (!isEmpty(controller)) {
      try {
        const data = await fetcher({ controller, params })

        return {
          name,
          controller,
          data,
          params,
        }
      } catch (e) {
        return thunkAPI.rejectWithValue({
          name,
          controller,
          message: e.message,
          code: e.code,
        })
      }
    }

    return thunkAPI.rejectWithValue({
      name,
      controller,
      message: 'Protocol not found',
    })
  },
)

const protocolSlice = createSlice({
  name: 'protocol',
  initialState: {},
  extraReducers: builder => {
    builder
      .addCase(loadProtocol.pending, (state, action) => {
        state[action.meta.arg.name] = {
          controller: action.meta.arg.controller,
          status: 'started',
          error: '',
          data: state[action.meta.arg.name]?.data ?? {},
        }
      })
      .addCase(loadProtocol.fulfilled, (state, action) => {
        state[action.payload.name] = {
          controller: action.payload.controller,
          status: 'done',
          error: '',
          data: action.payload.data,
        }
      })
      .addCase(loadProtocol.rejected, (state, action) => {
        state[action.payload.name] = {
          status: 'failed',
          error: action.payload.message,
          data: state?.[action.payload?.name]?.data ?? {},
        }
      })
      .addCase(loadProtocolIndex.pending, (state, action) => {
        state[action.meta.arg.name] = {
          controller: action.meta.arg.controller,
          status: 'started',
          error: '',
          data: state[action.meta.arg.name]?.data ?? {},
        }
      })
      .addCase(loadProtocolIndex.fulfilled, (state, action) => {
        state[action.payload.name] = {
          controller: action.payload.controller,
          status: 'done',
          error: '',
          data: action.payload.data,
        }
      })
      .addCase(loadProtocolIndex.rejected, (state, action) => {
        state[action.payload.name] = {
          status: 'failed',
          error: action.payload.message,
          data: state?.[action.payload?.name]?.data ?? {},
        }
      })
  },
})

export const getProtocol = (state, protocolName) =>
  state?.protocol[protocolName] ?? {}

export default protocolSlice.reducer
