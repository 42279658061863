import React, { useMemo, useRef, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Fab from '@mui/material/Fab'
import Btn from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Popper from '@mui/material/Popper'
import Paper from '@mui/material/Paper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import find from 'lodash/find'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'

import Colors from '#/styles/Old/Colors'
import Palette from '#/styles/Palette'

const useStyles = makeStyles(theme => ({
  btn: {
    margin: 5,
    whiteSpace: 'nowrap',
    padding: '0 16px',
  },
  btnIcon: {
    'color': theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.54)' : '#fff',
    'backgroundColor': 'transparent',
    'padding': '0 !important',
    'minWidth': '40px',
    '&:hover': {
      backgroundColor:
        theme.palette.mode === 'light'
          ? 'rgba(0, 0, 0, 0.04)'
          : 'rgba(255, 255, 255, 0.08)',
    },
  },
  btnCreate: {
    'color': '#fff',
    'backgroundColor': Palette.green.self[500],
    '&:hover': {
      backgroundColor: Palette.green.self[600],
    },
  },
  btnModerate: {
    'color': '#fff',
    'backgroundColor': Colors.moderationBadge,
    '&:hover': {
      backgroundColor: Colors.hover.moderationBadge,
    },
  },
  btnConfirm: {
    'borderColor': Colors.primary,
    'color': Colors.primary,
    'backgroundColor': Colors.transparent,
    '&:hover': {
      color: Colors.hover.primary,
      borderColor: Colors.hover.primary,
    },
  },
  btnWarn: {
    'color': '#fff',
    'backgroundColor': Palette.orange.self[500],
    '&:hover': {
      backgroundColor: Palette.orange.self[600],
    },
  },
  btnDanger: {
    'color': 'white',
    'backgroundColor': Palette.red.self[400],
    '&:hover': {
      backgroundColor: Palette.red.self[500],
    },
  },
  btnWhite: {
    '&:hover': {
      backgroundColor: Colors.lighterGray,
    },
  },
  btnAction: {
    width: '40px',
    minWidth: 'unset',
    minHeight: 0,
    lineHeight: 'initial',
    padding: 0,
  },
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  popperClose: {
    pointerEvents: 'none',
  },
  dropIcon: {
    padding: 0,
    lineHeight: 0,
  },
}))

/* A custom button that can be used as a Fab or a Btn. */
const CustomButton = React.forwardRef(({ children, variant, ...rest }, ref) => {
  let Component

  if (variant && ['round', 'extended'].includes(variant)) {
    Component = Fab
  } else {
    Component = Btn
  }

  return (
    <Component ref={ref} {...rest} {...(variant ? { variant } : {})}>
      {children}
    </Component>
  )
})

const Button = ({
  dropdownItems,
  btnClick = () => {},
  onClickParams,
  asAction,
  asIcon,
  asCreate,
  asModerate,
  asConfirm,
  asWarning,
  asDanger,
  asWhite,
  btnClass = '',
  btnProps = {},
  children = '',
  noDropdownIcon,
  customContainerStyle = {},
}) => {
  const [open, setOpen] = useState(false)
  const id = useMemo(
    () => `menu-list-grow-${Math.floor(Math.random() * 100)}`,
    [],
  )
  const anchorfRef = useRef(null)
  const classes = useStyles()

  function handleToggle(itemClick, runBtnClick = true) {
    if (runBtnClick) {
      btnClick()
    }

    if (!isEmpty(dropdownItems)) {
      if (isFunction(itemClick)) {
        if (!isEmpty(onClickParams)) itemClick(onClickParams)
        else itemClick()
      }
      setOpen(prev => !prev)
    }
  }

  function handleClose() {
    setOpen(false)
  }

  const className = [classes.btn]
  if (asAction) {
    className.push(classes.btnAction)
  }
  if (asCreate) {
    className.push(classes.btnCreate)
  }
  if (asModerate) {
    className.push(classes.btnModerate)
  }
  if (asConfirm) {
    className.push(classes.btnConfirm)
    className.push(classes.btnAction)
  }
  if (asWarning) {
    className.push(classes.btnWarn)
  }
  if (asDanger) {
    className.push(classes.btnDanger)
  }
  if (asWhite) {
    className.push(classes.btnWhite)
  }
  if (asIcon) {
    className.push(classes.btnIcon)
  }
  if (!isEmpty(btnClass)) {
    className.push(btnClass)
  }

  if (dropdownItems !== null && !isEmpty(dropdownItems)) {
    if (isEmpty(find(dropdownItems, { enabled: true }))) {
      return null
    }
  }

  return (
    <div style={customContainerStyle}>
      <CustomButton
        {...btnProps}
        ref={anchorfRef}
        aria-owns={open ? id : null}
        aria-haspopup="true"
        className={className.join(' ')}
        onClick={handleToggle}
      >
        {children}
        {!isEmpty(dropdownItems) && !noDropdownIcon ? (
          <div className={classes.dropIcon}>
            <KeyboardArrowDown />
          </div>
        ) : null}
      </CustomButton>
      {!isEmpty(dropdownItems) ? (
        <Popper
          open={open}
          anchorEl={anchorfRef.current}
          transition
          disablePortal
          placement="bottom-end"
          style={{ zIndex: 2 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper id={id}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList>
                    {dropdownItems.map((item, index) =>
                      item.enabled ? (
                        <MenuItem
                          key={index}
                          onClick={() => handleToggle(item.itemClick, false)}
                        >
                          {item.title}
                        </MenuItem>
                      ) : null,
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      ) : null}
    </div>
  )
}

export default Button
